import React from 'react';
import {Box} from "@mui/material";

interface IMinionCompendiumPageInput {

}

const MinionCompendiumPage = ({}: IMinionCompendiumPageInput) => {


    return (
        <Box>

            test
        </Box>
    )
}

export default MinionCompendiumPage